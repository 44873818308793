import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { TabsModule } from "ngx-bootstrap/tabs";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { AlertModule } from "ngx-bootstrap/alert";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { ModalModule } from "ngx-bootstrap/modal";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { PopoverModule } from "ngx-bootstrap/popover";

import { IndexComponent } from "./index/index.component";
import { ProfilepageComponent } from "./mainPages/profilepage/profilepage.component";
import { RegisterpageComponent } from "./mainPages/registerpage/registerpage.component";
import { LandingpageComponent } from "./mainPages/landingpage/landingpage.component";

import { HeaderComponent } from './commonCompo/header/header.component';
import { FooterComponent } from './commonCompo/footer/footer.component';

import { pocketLibbComponent } from './mainPages/stories/pocketLibb/pocketLibb.component';
import { asfComponent } from './mainPages/stories/asf/asf.component';
import { groffonComponent } from './mainPages/stories/groffon/groffon.component';
import { infoinsComponent } from './mainPages/stories/infoins/infoins.component';
import { optimoComponent } from './mainPages/stories/optimo/optimo.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot()
  ],
  declarations: [
    IndexComponent,
    ProfilepageComponent,
    RegisterpageComponent,
    LandingpageComponent,
    HeaderComponent,
    FooterComponent,
    pocketLibbComponent,
    asfComponent,
    groffonComponent,
    infoinsComponent,
    optimoComponent
  ],
  exports: [
    IndexComponent,
    ProfilepageComponent,
    RegisterpageComponent,
    LandingpageComponent,
    pocketLibbComponent,
    asfComponent,
    groffonComponent,
    infoinsComponent,
    optimoComponent
  ],
  providers: []
})
export class PagesModule {}
