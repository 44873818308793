import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import noUiSlider from "nouislider";
import { LoaderService } from '../../loader.service';

@Component({
  selector: "app-index",
  templateUrl: "index.component.html"
})

export class IndexComponent implements OnInit, OnDestroy, AfterViewInit {

  isLoading: boolean = false;
  
  isCollapsed = true;
  focus;
  focus1;
  focus2;
  date = new Date();
  pagination = 3;
  pagination1 = 1;
  // constructor() {}
  
  constructor(
    private route: ActivatedRoute, 
    private loaderService: LoaderService, 
    private cdr: ChangeDetectorRef
  ) {}

  // ngAfterViewInit() {
  //   this.route.fragment.subscribe(fragment => {
  //     if (fragment) {
  //       const element = document.getElementById(fragment);
  //       if (element) {
  //         element.scrollIntoView({ behavior: 'smooth' });
  //       }
  //     }
  //   });
  // }

  ngAfterViewInit() {
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        const element = document.getElementById(fragment);
        if (element) {
          // Delay scrolling to ensure the element is rendered
          setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth' });
          }, 0);
        }
      }
    });
  }

  scrollToDownload(element: any) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  ngOnInit() {
    this.loaderService.show();
    // Simulate an async operation
    setTimeout(() => {
      this.loaderService.hide();
    }, 2000); // 3 seconds delay

    this.loaderService.loaderState.subscribe((state: boolean) => {
      this.isLoading = state;
      this.cdr.detectChanges(); // Manually trigger change detection
    });

    var body = document.getElementsByTagName("body")[0];
    body.classList.add("index-page");

    

    // var slider = document.getElementById("sliderRegular");

    // noUiSlider.create(slider, {
    //   start: 40,
    //   connect: false,
    //   range: {
    //     min: 0,
    //     max: 100
    //   }
    // });

    // var slider2 = document.getElementById("sliderDouble");

    // noUiSlider.create(slider2, {
    //   start: [20, 60],
    //   connect: true,
    //   range: {
    //     min: 0,
    //     max: 100
    //   }
    // });
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("index-page");
  }
  canActivate() {
    return true;
  }
}
