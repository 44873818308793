import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { IndexComponent } from "./pages/index/index.component";
import { ProfilepageComponent } from "./pages/mainPages/profilepage/profilepage.component";
import { RegisterpageComponent } from "./pages/mainPages/registerpage/registerpage.component";
import { LandingpageComponent } from "./pages/mainPages/landingpage/landingpage.component";

import { pocketLibbComponent } from './pages/mainPages/stories/pocketLibb/pocketLibb.component';
import { asfComponent } from './pages/mainPages/stories/asf/asf.component';
import { groffonComponent } from './pages/mainPages/stories/groffon/groffon.component';
import { infoinsComponent } from './pages/mainPages/stories/infoins/infoins.component';
import { optimoComponent } from './pages/mainPages/stories/optimo/optimo.component';

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: IndexComponent },
  { path: "profile", component: ProfilepageComponent },
  { path: "register", component: RegisterpageComponent },
  { path: "landing", component: LandingpageComponent },
  { path: "pocketLibb", component: pocketLibbComponent },
  { path: "asf", component: asfComponent },
  { path: "groffon", component: groffonComponent },
  { path: "infoins", component: infoinsComponent },
  { path: "optimo", component: optimoComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration: 'enabled' })
  ],
  exports: []
})
export class AppRoutingModule {}
