import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { LoaderService } from '../../../../loader.service';

@Component({
  selector: "app-optimo",
  templateUrl: "optimo.component.html"
})
export class optimoComponent implements OnInit, OnDestroy {
  isCollapsed = true;
  public isLoading: boolean = false;
  
  constructor(private loaderService: LoaderService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("optimo-page");

     // loader
     this.loaderService.show();
     setTimeout(() => {
       this.loaderService.hide();
     }, 1000); 
 
     this.loaderService.loaderState.subscribe((state: boolean) => {
       this.isLoading = state;
       this.cdr.detectChanges(); // Manually trigger change detection
     });
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("optimo-page");    
  }
}
