import {
  Component,
  OnInit,
  Renderer2,
  HostListener,
  Inject, 
  ChangeDetectorRef
} from "@angular/core";
import { Location } from "@angular/common";
import { DOCUMENT } from "@angular/common";

import { LoaderService } from './loader.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

  public isLoading: boolean = false;

  constructor(
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    public location: Location,
    @Inject(DOCUMENT) document
  ) {}
  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    if (window.pageYOffset > 100) {
      var element = document.getElementById("navbar-top");
      if (element) {
        element.classList.remove("navbar-transparent");
        element.classList.add("bg-danger");
      }
    } else {
      var element = document.getElementById("navbar-top");
      if (element) {
        element.classList.add("navbar-transparent");
        element.classList.remove("bg-danger");
      }
    }
  }
  ngOnInit() {
    this.onWindowScroll(event);

    this.loaderService.loaderState.subscribe((state: boolean) => {
      this.isLoading = state;
      this.cdr.detectChanges(); // Manually trigger change detection
    });
  }
}
